import { BookContentPdf } from '~/components/pages/ReaderPDF/types';
import BookTypes, { BookFormats, BookReadingTime } from '~/types/app/book';
import { callApi } from './api';
import { BookContentEpub } from '~/types/app/reader';

export const stringifyReadingTime = (readingTime?: BookReadingTime): string =>
    readingTime ? `${readingTime.hours}t ${readingTime.minutes}m lesing` : '';

export async function buildEpubBookMetadataFromManifest(
    bookContent: BookContentEpub,
    request: Request,
    ean: string,
    url: string,
    userHasAccess: boolean,
): Promise<BookTypes> {
    if (!bookContent?.manifestUrl) {
        throw new Error('Trying to build up epub metadata from manifest but manifest URL is missing');
    }

    const path = new URL(bookContent.manifestUrl)?.pathname;
    const manifestResponse = await callApi({ method: 'GET', request, path });

    if (!manifestResponse.success) {
        throw new Error(
            'Failed to load book metadata from search und unable to get manifest to build up book metadata.',
        );
    }

    const authors = [];
    if (manifestResponse.data.metadata?.author?.name) {
        authors.push({
            key: '',
            valueOriginalFormat: manifestResponse.data.metadata?.author?.name,
            value: `${manifestResponse.data.metadata?.author?.name?.replace(',', '').split(' ')[1]} ${
                manifestResponse.data.metadata?.author?.name?.replace(',', '').split(' ')[0]
            }`,
        });
    }
    const book: BookTypes = {
        id: ean,
        ean: ean,
        isbn: ean,
        title: manifestResponse.data.metadata?.title ?? '',
        authors: authors,
        editors: [],
        publishedYear: 0,
        imageUrl: '',
        imageFallbackUrl: '',
        imagePaperCopyFallbackUrl: '',
        publishers: [],
        languages: [],
        edition: 0,
        note: '',
        meta: {
            availableInSubscription: false,
            userHasInventoryAccess: userHasAccess,
            url: url,
            basedOnISBN: '',
            format: BookFormats.Epub,
            rentalOptions: [],
            pageNum: 0,
            topics: [],
            chapters: { readingOrder: [], titles: {}, weights: {} },
            lowestAvailablePrice: 1,
            hasMultiplePrices: false,
            availableOnAllvit: true,
            freeBook: false,
        },
    };
    return book;
}

export function buildFallbackBookMetadata(
    bookContent: BookContentEpub | BookContentPdf,
    ean: string,
    url: string,
    userHasAccess: boolean,
): BookTypes {
    return {
        id: ean,
        ean: ean,
        isbn: ean,
        title: url?.replace('/bok/', '').replace('/lese', '') ?? '',
        authors: [],
        editors: [],
        publishedYear: 0,
        imageUrl: '',
        imageFallbackUrl: '',
        imagePaperCopyFallbackUrl: '',
        publishers: [],
        languages: [],
        edition: 0,
        note: '',
        meta: {
            topics: [],
            url: url?.replace('/lese', '') ?? '',
            basedOnISBN: '',
            rentalOptions: [],
            pageNum: 0,
            format: bookContent?.format ?? BookFormats.Unknown,
            chapters: { readingOrder: [], titles: {}, weights: {} },
            availableInSubscription: false,
            availableOnAllvit: true,
            lowestAvailablePrice: 1,
            hasMultiplePrices: false,
            freeBook: false,
            userHasInventoryAccess: userHasAccess, // since user was able to get the bookContent we assume some kind of access is allowed
        },
    };
}

export function getBookImageUrl(book?: BookTypes, skipDefault?: boolean): string {
    if (!book) {
        return '';
    }

    if (skipDefault) {
        return book.imagePaperCopyFallbackUrl !== ''
            ? book.imagePaperCopyFallbackUrl
            : book.imageFallbackUrl
              ? book.imageFallbackUrl
              : '';
    }

    return book.imageUrl !== ''
        ? book.imageUrl
        : book.imagePaperCopyFallbackUrl !== ''
          ? book.imagePaperCopyFallbackUrl
          : book.imageFallbackUrl
            ? book.imageFallbackUrl
            : '';
}
