import { useTheme, SvgIconProps, alpha } from '@mui/material';

import { Dot as DotIcon } from '~/components/atoms/Icon';
import { mergeSx } from '~/utils/mui';

const Dot = ({ sx, ...props }: SvgIconProps) => {
    const theme = useTheme();

    return (
        <DotIcon
            {...props}
            sx={mergeSx(
                {
                    mb: '2px',
                    fontSize: 6,
                    color:
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[400]
                            : alpha(theme.palette.common.white, 0.4),
                },
                sx,
            )}
        />
    );
};

export default Dot;
