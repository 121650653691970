import { SvgIcon, SvgIconProps } from '@mui/material';

const DotIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 15 15">
            <circle cx="7.5" cy="7.5" r="7.5" fill="currentColor" />
        </SvgIcon>
    );
};

export default DotIcon;
